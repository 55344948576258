<template>
  <v-container fluid class="ml-1">
    <template v-if="$store.getters['user/isLoggedIn']">
      <v-row>
        <v-col>
          <ObjectEditor
            Title="System Basics"            
            :ObjectList="ShowData"
            :Data="Data"
            :RefreshCounter="UpdatedData"
            @update:data="updateData($event)"
          />
          <ObjectEditor
            Title="Valid Relays"
            Description="Select which relays that are valid on this interlock box, and therefore monitored and logged"
            :ObjectList="RelayData"
            :Data="Data"
            :RefreshCounter="UpdatedData"
            @update:data="updateData($event)"
          />
          <ObjectEditor
            Title="Interlock Configuration"
            Description="Define the interlock switching behavior for the CORAL channels"
            ShowAdd="true"
            :ObjectList="InterlockChannels"
            :Data="Data"
            :RefreshCounter="UpdatedData"
            @update:data="updateData($event)"
            @update:channel="updateChannel($event)"
            @update:add="addChannel()"
            @update:delete="deleteChannel($event)"
          />
        </v-col>
      </v-row>
    </template>
    <template v-if="!$store.getters['user/isLoggedIn']">
      <v-row>
        <v-col cols="12"> <Header /> </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <UserLogin />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>


<script>
import UserLogin from "@/components/user/UserLogin";
import Header from "@/components/Header";
import ObjectEditor from "@/components/dataedit/ObjectEditor";

export default {
  components: {
    Header,
    ObjectEditor,
    UserLogin,
  },

  data: () => ({
    newChannelSetup: {
      coral: "Enter the coral name",
      detail: "say what is being interlocked",
      actions: [],
      autorevert: {
        value: 24,
        units: "hours",
      },
    },
    Data: [],
    UpdatedData: 0,
    ShowData: [
      {
        divider: true,
        style: "subheader",
        text: "System Info",
      },
      {
        color: "blue-grey lighten-2",
        icon: "mdi-label",
        item: "system.name",
        style: "string",
        name: "Name",
        details: "Short description of the interlock box's purpose",
      },
      {
        color: "blue-grey lighten-2",
        icon: "mdi-map-marker",
        item: "system.location",
        style: "string",
        name: "Location",
        details: "Short description of the interlock location in the lab",
      },
      {
        divider: true,
        style: "subheader",
        text: "Timing",
      },
      {
        color: "blue-grey lighten-2",
        icon: "mdi-clock",
        item: "configuration.autorevert.checkevery",
        style: "physical",
        name: "Check Frequency",
        details: "Interval for checking for autorevert events",
        units: ["seconds", "minutes"],
      },
    ],
    RelayData: [
      {
        color: "blue",
        icon: "mdi-check",
        items: [
          { name: "AC 1", item: "hardware~ACRelays~ac.1" },
          { name: "AC 2", item: "hardware~ACRelays~ac.2" },
          { name: "AC 3", item: "hardware~ACRelays~ac.3" },
          { name: "AC 4", item: "hardware~ACRelays~ac.4" },
          { name: "DC 1", item: "hardware~DCRelays~dc.1" },
          { name: "DC 2", item: "hardware~DCRelays~dc.2" },
        ],
        style: "onofflist",
        name: "Valid Relays",
        details: "These relays are valid for this interlock box",
      },
    ],
    ConfigData: {
      color: "blue-grey lighten-2",
      icon: "mdi-check",
      foreachchannel: [
        {
          divider: true,
          style: "subheader",
          text: "Tool Definition",
        },
        {
          color: "blue-grey lighten-2",
          icon: "mdi-wrench",
          name0: "Channel",
          item0: "[index]",
          item: "coral",
          valid: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
          ],
          style: "channelname",
          name: "Tool Name",
          details: "CORAL tool name",
        },
        {
          color: "blue-grey lighten-2",
          icon: "mdi-magnify",
          item: "detail",
          style: "string",
          name: "Interlock method",
          details: "Short description of the interlock location in the lab",
        },
        {
          color: "blue-grey lighten-2",
          icon: "mdi-play",
          style: "relay",
          relays: [
            {
              name: "AC 1 on",
              switch: "ac.1",
              icon: "mdi-power-plug",
              color: "green",
            },
            {
              name: "AC 2 on",
              switch: "ac.2",
              icon: "mdi-power-plug",
              color: "green",
            },
            {
              name: "AC 3 on",
              switch: "ac.3",
              icon: "mdi-power-plug",
              color: "green",
            },
            {
              name: "AC 4 on",
              switch: "ac.4",
              icon: "mdi-power-plug",
              color: "green",
            },
            {
              name: "DC 1 on",
              switch: "dc.1",
              icon: "mdi-electric-switch-closed",
              color: "green",
            },
            {
              name: "DC 2 on",
              switch: "dc.2",
              icon: "mdi-electric-switch-closed",
              color: "green",
            },
            {
              name: "AC 1 off",
              switch: "ac.1",
              invert: true,
              icon: "mdi-power-plug-off",
              color: "red",
            },
            {
              name: "AC 2 off",
              switch: "ac.2",
              invert: true,
              icon: "mdi-power-plug-off",
              color: "red",
            },
            {
              name: "AC 3 off",
              switch: "ac.3",
              invert: true,
              icon: "mdi-power-plug-off",
              color: "red",
            },
            {
              name: "AC 4 off",
              switch: "ac.4",
              invert: true,
              icon: "mdi-power-plug-off",
              color: "red",
            },
            {
              name: "DC 1 off",
              switch: "dc.1",
              invert: true,
              icon: "mdi-electric-switch",
              color: "red",
            },
            {
              name: "DC 2 off",
              switch: "dc.2",
              invert: true,
              icon: "mdi-electric-switch",
              color: "red",
            },
          ],
          name: "Channel",
          details: "CORAL tool name",
        },
        {
          color: "blue-grey lighten-2",
          icon: "mdi-lightbulb-on",
          item: "in.1",
          style: "indicatorcolor",
          name: "Indicator",
          details: "Indictor box behavior",
          valid: ["1", "2", "3", "4"],
        },
        {
          color: "blue-grey lighten-2",
          icon: "mdi-pause",
          item: "autorevert",
          style: "physical",
          name: "Auto-Revert",
          details: "Automatically disengage ",
          units: ["minutes", "hours", "days"],
        },
      ],
      style: "toolconfig",
      name: "Coral Definitions",
      details: "Defines the relay behavior on interlock commands.",
    },
  }),

  computed: {
    InterlockChannels: function () {
      // go through the channel list      
      // make sure to trigger this when data changed
      this.UpdatedData
      var showList = [];
      if (this.Data === undefined) return [];
      for (const [channelnumber, configdetails] of Object.entries(
        this.Data.configuration
      )) {
        if (configdetails.coral !== undefined) {
          for (let item of this.ConfigData.foreachchannel) {
            var entry = JSON.parse(JSON.stringify(item));

            if (entry.item === "[index]") {
              entry.item = channelnumber;
            } else {
              entry.item = "configuration." + channelnumber + "." + entry.item;
            }
            if (entry.item0 === "[index]") {
              entry.item0 = channelnumber;
            }
            //console.log(JSON.stringify(entry));
            showList.push(entry);
          }
        }
      }
      //console.log("STARTING LIST DONE");
      return showList;
    },
  },

  methods: {
    loadData: async function () {
      try {
        // get the setup data
        console.log("Get config");
        //var config = await dataloader.getConfig(this.configReloadKey);
        const config = await this.$store.getters["data/getDataList"]("config");
        //console.log(JSON.stringify(config))
        const state = await this.$store.getters["data/getDataList"]("state");
        //console.log(JSON.stringify(state))
        console.log("Get config done");
        if (config.error || state.error) {
          console.log("Error loading data");
          return;
        }
        if (config.data === undefined) return;
        if (state.data === undefined) return;
        this.Data = JSON.parse(JSON.stringify(config.data));
        this.Data.hardware = JSON.parse(JSON.stringify(state.data.hardware));
        this.UpdatedData++;
      } catch (ex) {
        console.log("error 1");
        return [];
      }
    },

    updateData: function (evt) {
      // put the event result (update from one of the data displays) into the data object
      var target = this.Data;
      if (evt.path === undefined) return;
      for (var k = 0; k < evt.path.length - 1; k++) {
        target = target[evt.path[k]];
        if (target === undefined) return;
      }
      target[evt.path[evt.path.length - 1]] = evt.value;
      console.log(JSON.stringify(this.Data));
    },
    updateChannel: function (evt) {
      console.log(JSON.stringify(evt));
      // special case: renaming of a channel number:
      var target = this.Data.configuration;
      var ch1 = JSON.parse(JSON.stringify(target[evt.oldchannel]));
      if (target[evt.newchannel] !== undefined) {
        // target already exists? Then simply flip channels
        var ch2 = JSON.parse(JSON.stringify(target[evt.newchannel]));
        target[evt.oldchannel] = ch2;
        target[evt.newchannel] = ch1;
      } else {
        target[evt.newchannel] = ch1;
        delete target[evt.oldchannel];
      }
      this.UpdatedData++;
      console.log(JSON.stringify(this.Data));
    },
    deleteChannel: function (evt) {
      console.log("DELETE");
      console.log(JSON.stringify(evt));
      var target = this.Data.configuration;
      delete target[evt.channel];
      this.UpdatedData++;
      console.log(JSON.stringify(this.Data));
    },
    addChannel: function () {
      // add a new channel, on the first there is
      var target = this.Data.configuration;
      for (var k = 0; k < 16; k++) {
        if (target[k] === undefined) {
          target[k] = JSON.parse(JSON.stringify(this.newChannelSetup));
          break;
        }
      }
      this.UpdatedData++;
      console.log(JSON.stringify(this.Data));
    },
  },

  created() {
    this.loadData();
  },
};
</script> 
