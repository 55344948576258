var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-auto",attrs:{"color":"primary","outlined":"","width":"500","max-width":"500"}},[_c('v-card-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.Title))]),_c('v-list',[(_vm.Description !== undefined)?_c('v-subheader',[_vm._v(" "+_vm._s(_vm.Description)+" ")]):_vm._e(),_vm._l((_vm.ObjectList),function(item,i){return [_c('v-list-item',{key:i,attrs:{"dense":"","dummy":_vm.RefreshCounter}},[(item.style === 'string')?_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":item.name,"value":_vm.getItemToBind(item),"hint":item.details},on:{"input":function($event){_vm.$emit('update:data', _vm.getUpdateCommand(item.item, $event))}}},[_c('v-icon',{attrs:{"slot":"prepend","color":item.color,"dark":""},slot:"prepend"},[_vm._v(_vm._s(item.icon))])],1)],1):_vm._e(),(item.style === 'channelname')?_c('v-list-item-content',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"items":item.valid,"value":item.item0,"label":item.name0},on:{"input":function($event){return _vm.$emit('update:channel', {
                    oldchannel: item.item0,
                    newchannel: $event,
                  })}}},[_c('v-icon',{attrs:{"slot":"prepend","color":item.color,"dark":""},slot:"prepend"},[_vm._v(_vm._s(item.icon))])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":item.name,"value":_vm.getItemToBind(item),"hint":item.details},on:{"input":function($event){_vm.$emit('update:data', _vm.getUpdateCommand(item.item, $event))}}})],1)],1)],1):_vm._e(),(item.style === 'indicatorcolor')?_c('v-list-item-content',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-select',{attrs:{"items":item.valid,"value":item.item,"label":"Indicator"},on:{"input":function($event){return _vm.$emit('update:color', $event)}}},[_c('v-icon',{attrs:{"slot":"prepend","color":item.color,"dark":""},slot:"prepend"},[_vm._v(_vm._s(item.icon))])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"ON Duration 1","value":"2.0"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"green"},slot:"prepend"},[_vm._v("mdi-checkbox-blank-circle")])],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"ON Duration 2","value":"0.0"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"amber"},slot:"prepend"},[_vm._v("mdi-checkbox-blank-circle")])],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"OFF Duration 1","value":"2.0"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"red"},slot:"prepend"},[_vm._v("mdi-checkbox-blank-circle")])],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"OFF Duration 2","value":"0.0"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"amber"},slot:"prepend"},[_vm._v("mdi-checkbox-blank-circle")])],1)],1)],1)],1)],1)],1):_vm._e(),(item.style === 'physical')?_c('v-list-item-content',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":item.name,"value":_vm.getItemToBind(item).value,"hint":item.details},on:{"input":function($event){_vm.$emit(
                    'update:data',
                    _vm.getUpdateCommandAddon(item.item, ['value'], $event)
                  )}}},[_c('v-icon',{attrs:{"slot":"prepend","color":item.color,"dark":""},slot:"prepend"},[_vm._v(_vm._s(item.icon))])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":item.units,"value":_vm.fixPlural(_vm.getItemToBind(item).units),"label":"Units"},on:{"input":function($event){_vm.$emit(
                    'update:data',
                    _vm.getUpdateCommandAddon(item.item, ['units'], $event)
                  )}}})],1)],1)],1):_vm._e(),(item.style === 'subheader')?_c('v-list-item-content',[(item.divider)?_c('v-divider'):_vm._e(),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1):_vm._e(),(item.style === 'dropdown')?_c('v-list-item-content',[_c('v-select',{attrs:{"items":item.valid,"value":item.item,"label":item.name}})],1):_vm._e(),(item.style === 'onofflist')?_c('v-list-item-content',[_c('v-btn-toggle',{attrs:{"value":_vm.getValidButtons(item.items),"multiple":"","color":"indigo accent-4"}},_vm._l((item.items),function(button,b){return _c('v-btn',{key:b},[_vm._v(_vm._s(button.name))])}),1)],1):_vm._e(),(item.style === 'relay')?_c('v-list-item-content',[_c('v-combobox',{attrs:{"items":_vm.getRelaySelection(item, 'name'),"label":"Relay behavior","persistent-hint":"","multiple":"","small-chips":"","chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item),attrs:{"input-value":data.selected},on:{"click:close":function($event){return data.parent.selectItem(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{class:_vm.getEntryFor(item, data.item).color,attrs:{"dark":""}},[_vm._v(_vm._s(_vm.getEntryFor(item, data.item).icon))])],1),_vm._v(" "+_vm._s(data.item)+" ")],1)]}}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},[_c('v-icon',{attrs:{"slot":"prepend","color":item.color,"dark":""},slot:"prepend"},[_vm._v(_vm._s(item.icon))])],1)],1):_vm._e(),(item.style === 'channelname')?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","fab":""},on:{"click":function($event){return _vm.$emit('update:delete', {
                channel: item.item0,
              })}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)]})],2),_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.ShowAdd),expression:"ShowAdd"}],attrs:{"color":"primary","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":function($event){return _vm.$emit('update:add')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }