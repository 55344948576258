<template>
  <v-card color="primary" class="ma-auto" outlined width="500" max-width="500">
    <v-card-title class="white--text">{{ Title }}</v-card-title>

    <v-list>
      <v-subheader v-if="Description !== undefined">
        {{ Description }}
      </v-subheader>
      <template v-for="(item, i) in ObjectList">
        <v-list-item :key="i" dense :dummy="RefreshCounter">
          <v-list-item-content v-if="item.style === 'string'">
            <v-text-field
              :label="item.name"
              :value="getItemToBind(item)"
              :hint="item.details"
              @input="$emit('update:data', getUpdateCommand(item.item, $event))"
              ><v-icon slot="prepend" :color="item.color" dark>{{
                item.icon
              }}</v-icon></v-text-field
            >
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'channelname'">
            <v-row dense
              ><v-col>
                <v-select
                  :items="item.valid"
                  :value="item.item0"
                  :label="item.name0"
                  @input="
                    $emit('update:channel', {
                      oldchannel: item.item0,
                      newchannel: $event,
                    })
                  "
                >
                  <v-icon slot="prepend" :color="item.color" dark>{{
                    item.icon
                  }}</v-icon></v-select
                > </v-col
              ><v-col cols="8">
                <v-text-field
                  :label="item.name"
                  :value="getItemToBind(item)"
                  :hint="item.details"
                  @input="
                    $emit('update:data', getUpdateCommand(item.item, $event))
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'indicatorcolor'">
            <v-row dense
              ><v-col>
                <v-select
                  :items="item.valid"
                  :value="item.item"
                  label="Indicator"
                  @input="$emit('update:color', $event)"
                >
                  <v-icon slot="prepend" :color="item.color" dark>{{
                    item.icon
                  }}</v-icon></v-select
                > </v-col
              ><v-col cols="8">                
                <v-row dense
                  ><v-col
                    ><v-text-field label="ON Duration 1" value="2.0"
                      ><v-icon slot="prepend" color="green"
                        >mdi-checkbox-blank-circle</v-icon
                      ></v-text-field
                    ></v-col
                  ><v-col
                    ><v-text-field label="ON Duration 2" value="0.0"
                      ><v-icon slot="prepend" color="amber"
                        >mdi-checkbox-blank-circle</v-icon
                      ></v-text-field
                    ></v-col
                  ></v-row
                >
                <v-row dense
                  ><v-col
                    ><v-text-field label="OFF Duration 1" value="2.0"
                      ><v-icon slot="prepend" color="red"
                        >mdi-checkbox-blank-circle</v-icon
                      ></v-text-field
                    ></v-col
                  ><v-col
                    ><v-text-field label="OFF Duration 2" value="0.0"
                      ><v-icon slot="prepend" color="amber"
                        >mdi-checkbox-blank-circle</v-icon
                      ></v-text-field
                    ></v-col
                  ></v-row
                >
              </v-col>
            </v-row>
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'physical'">
            <v-row dense
              ><v-col>
                <v-text-field
                  :label="item.name"
                  :value="getItemToBind(item).value"
                  :hint="item.details"
                  @input="
                    $emit(
                      'update:data',
                      getUpdateCommandAddon(item.item, ['value'], $event)
                    )
                  "
                  ><v-icon slot="prepend" :color="item.color" dark>{{
                    item.icon
                  }}</v-icon></v-text-field
                > </v-col
              ><v-col cols="4">
                <v-select
                  :items="item.units"
                  :value="fixPlural(getItemToBind(item).units)"
                  label="Units"
                  @input="
                    $emit(
                      'update:data',
                      getUpdateCommandAddon(item.item, ['units'], $event)
                    )
                  "
                ></v-select>
              </v-col>
            </v-row>
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'subheader'">
            <v-divider v-if="item.divider"></v-divider>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'dropdown'">
            <v-select
              :items="item.valid"
              :value="item.item"
              :label="item.name"
            ></v-select>
          </v-list-item-content>
          <v-list-item-content v-if="item.style === 'onofflist'">
            <v-btn-toggle
              :value="getValidButtons(item.items)"
              multiple
              color="indigo accent-4"
            >
              <v-btn v-for="(button, b) in item.items" v-bind:key="b">{{
                button.name
              }}</v-btn>
            </v-btn-toggle>
          </v-list-item-content>

          <v-list-item-content v-if="item.style === 'relay'">
            <v-combobox
              v-model="select"
              :items="getRelaySelection(item, 'name')"
              label="Relay behavior"
              persistent-hint
              multiple
              small-chips
              chips
            >
              <v-icon slot="prepend" :color="item.color" dark>{{
                item.icon
              }}</v-icon>
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  <v-avatar left
                    ><v-icon :class="getEntryFor(item, data.item).color" dark>{{
                      getEntryFor(item, data.item).icon
                    }}</v-icon>
                  </v-avatar>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-list-item-content>

          <v-list-item-action v-if="item.style === 'channelname'">
            <v-btn
              icon
              fab
              @click="
                $emit('update:delete', {
                  channel: item.item0,
                })
              "
            >
              <v-icon color="primary">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>

    <v-fab-transition>
      <v-btn
        v-show="ShowAdd"
        color="primary"
        dark
        absolute
        bottom
        right
        fab
        @click="$emit('update:add')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-card>
</template>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>

<script>
var moment = require("moment");
import dataloader from "@/api/dataloader.js";

export default {
  data: () => ({
    Test: "",
    value: "",
    select: [],
    hasChanged: false,
    color: '#1976D2FF',
		mask: '!#XXXXXXXX',
		menu: false,
  }),

  props: {
    Title: String,
    Description: String,
    ObjectList: Array,
    Data: Object,
    ShowAdd: String,
    RefreshCounter: Number,
  },

  methods: {
    getUpdateCommand(target, newValue, seperator = ".") {
      const words = target.split(seperator);
      return { path: words, value: newValue };
    },
    getUpdateCommandAddon(target, addon, newValue, seperator = ".") {
      var result = this.getUpdateCommand(target, newValue, seperator);
      result.path = result.path.concat(addon);
      return result;
    },

    getItemToBind(item, seperator = ".") {
      var result = this.Data;      
      const words = item.item.split(seperator);
      console.log(JSON.stringify(result))
      console.log(JSON.stringify(words))
      for (const element of words) {
        if (result !== undefined) result = result[element];
      }
      return result;
    },
    fixPlural(item) {
      if (!item.endsWith("s")) return item + "s";
      return item;
    },

    getValidButtons(items) {
      var showNumber = [];
      for (var k = 0; k < items.length; k++) {
        if (this.getItemToBind(items[k], "~") == true) showNumber.push(k);
      }
      return showNumber;
    },

    getRelaySelection(item, value) {
      var selection = [];
      for (var entry of item.relays) {
        selection.push(entry[value]);
      }
      return selection;
    },
    getEntryFor(items, value) {
      const entry = items.relays.find((item) => item.name === value);
      return entry;
    },

    getDescription(item) {
      var s = item.description;
      return s;
    },
    getOverrideText(item) {
      return item.overrideText;
    },
    getActionDescription(item) {
      var s = "Ch." + item.channel + ": ";
      var actions = this.config.configuration[item.channel].actions;
      for (var entry of actions) {
        if (entry.switch !== undefined) {
          s = s + "(";
          if (entry.invert) s = s + "open ";
          else s = s + "close ";
          s = s + entry.switch;
          if (entry.requires > 1) s = s + " if " + entry.requires;
          s = s + ") ";
        }
      }
      return s;
    },
    isEngaged(item) {
      return item.value == 1;
    },
    buttonInterlock(item) {
      item.loading = true;
      // call the API
      if (item.value == 0) {
        dataloader.engage(item.channel);
      } else {
        dataloader.disengage(item.channel);
      }
      //var self = this
      setTimeout(() => {
        this.$store.dispatch("data/refreshState");
      }, this.stateTimeout * 1000);
    },
  },

  asyncComputed: {
    config: {
      async get() {
        try {
          // get the setup data
          //console.log("Get config");
          //var config = await dataloader.getConfig(this.configReloadKey);
          const config = await this.$store.getters["data/getDataList"](
            "config"
          );
          //console.log("Get config done");
          if (config.error) {
            console.log("Error loading data");
            return null;
          }
          if (config.data === undefined) return [];
          return config.data;
        } catch (ex) {
          console.log("error 1");
          return [];
        }
      },
      default: [],
    },
    state: {
      async get() {
        // get the setup data
        try {
          //console.log("Get state");
          //var state = await dataloader.getState(this.stateReloadKey);
          const state = await this.$store.getters["data/getDataList"]("state");
          //console.log("Get state done");
          if (state.error) {
            console.log("Error loading data");
            return null;
          }
          if (state.data === undefined) return [];
          return state.data;
        } catch (ex) {
          console.log("error 1");
          return [];
        }
      },
      default: [],
    },
    actionList: {
      async get() {
        // get the setup data
        var myState = await this.state;
        var myConfig = await this.config;
        //console.log(JSON.stringify(myState));
        //console.log(JSON.stringify(myConfig));
        if (myState == []) {
          console.log("waiting for data");
          return;
        }
        var channelStatus = myState.state.interlock;
        var channelOverride = myState.state.override;
        var channelSetup = myConfig.configuration;
        var permissions = this.$store.getters["user/permissions"];
        const timeNow = moment().valueOf();
        var results = [];
        //console.log(JSON.stringify(channelSetup));
        //console.log(JSON.stringify(channelStatus));
        //console.log(JSON.stringify(permissions));
        for (const [key, value] of Object.entries(channelSetup)) {
          if (value.coral !== undefined) {
            // set up default entry (not engaged)
            var entry = {
              color: "red lighten-1",
              icon: "mdi-minus",
              loading: false,
              description: value.coral,
              detail: value.detail,
              channel: key,
              value: channelStatus[key].value,
              canActivate: permissions.includes("engage"),
              buttonText: "Engage",
            };
            // if engaged
            if (channelStatus[key].value !== 0) {
              entry.icon = "mdi-check";
            }
            // check override
            if (channelOverride[key] !== undefined) {
              if (
                channelOverride[key].expires > timeNow &&
                channelOverride[key].value !== channelStatus[key].value
              ) {
                entry.value = channelOverride[key].value;
                entry.icon = "mdi-hand";
                entry.overrideText =
                  "manual override: " +
                  moment(channelOverride[key].action).format("MMM DD HH:mm");
              } else if (channelOverride[key].reverted !== undefined) {
                entry.overrideText =
                  "override reverted: " +
                  moment(channelOverride[key].reverted).format("MMM DD HH:mm");
              }
              if (
                channelOverride[key].value === channelStatus[key].value &&
                channelOverride[key].action !== undefined
              ) {
                entry.overrideText =
                  "override revoked: " +
                  moment(channelOverride[key].action).format("MMM DD HH:mm");
              }
            } else {
              if (channelStatus[key].action !== undefined) {
                entry.overrideText =
                  "coral action: " +
                  moment(channelStatus[key].action).format("MMM DD HH:mm");
              } else if (channelStatus[key].reverted !== undefined) {
                entry.overrideText =
                  "auto-disengaged: " +
                  moment(channelStatus[key].reverted).format("MMM DD HH:mm");
              }
            }
            if (entry.value === 1) {
              entry.buttonText = "Disengage";
              entry.color = "green lighten-1";
            }
            results.push(entry);
          }
        }
        return results;
      },
      default: [],
    },
  },
};
</script>